<template>
  <div>
    <portal to="header">
      <terminal-header :title="$t('Inventory')" @barcode="handleBarcode"/>
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema"/>
    </portal>

    <div v-if="action === 'scanBasket'">
      <assistant-object :data="assistantObject"/>
    </div>

    <div v-else>
      <q-carousel
          :model-value="action"
          transition-prev="scale"
          transition-next="scale"
          swipeable
          animated
          class="terminal-min-height"
          :style="`height: ${terminalContainerHeight}px;`"
      >
        <q-carousel-slide name="scanBasket" class="q-pa-none" :style="`height: ${terminalContainerHeight}px;`">
          <jumbotron/>
        </q-carousel-slide>

        <q-carousel-slide name="next" class="relative q-pa-none" :style="`height: ${terminalContainerHeight}px;`">
          <inventory-process-content
              :barcode="barcode"
              :queues="queues"
              :place="place"
              @block="handleBlock"
              @unblock="handleUnblock"
              @reset="handleReset"
          />
        </q-carousel-slide>
      </q-carousel>
    </div>

  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Vuex
import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'Inventory',
  components: {
    InventoryProcessContent: defineAsyncComponent(() => import('./InventoryProcessContent.vue'))
  },
  data () {
    return {
      action: 'scanBasket',
      queues: [],
      isBlocked: false,
      barcode: null,
      place: null,
      helper: null,
      assistantObject: {
        title: 'Please scan',
        caption: 'Location',
        captionColor: 'amber',
        image: 'scanBasketPlace'
      }
    }
  },
  computed: {
    ...mapGetters([
      'terminalContainerHeight'
    ]),
    tilesSchema () {
      return []
    }
  },
  mounted () {
    this.addHelper('Please, scan location!')
        .then(helper => {
          this.helper = helper
        })
  },
  unmounted () {
    if (this.helper) {
      this.removeHelper(this.helper.id)
    }

    if (this.place) {
      this.$channels.user.publish('closePlace', this.place)
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'removeHelper'
    ]),
    ...mapActions([
      'addHelper'
    ]),
    handleBlock () {
      this.isBlocked = true
    },
    handleUnblock () {
      this.isBlocked = false
    },
    handleReset () {
      this.action = 'scanBasket'
      this.queues = []
      this.isBlocked = false
      this.barcode = null

      if (this.place) {
        this.$channels.user.publish('closePlace', this.place)
      }

      this.place = null
    },
    handleBarcode (barcode) {

      if (this.action === 'scanBasket') {
        const isOpened = this.$utils.storeUtils.getLockedPlaceEvent(barcode.value)

        if (isOpened) {
          this.addErrorNotification(`Someone already work on this place!`)
          return Promise.resolve(null)
        }

        return this.$service.storagePlace.get(barcode.value)
            .then(place => {
              // if (place.type !== 'employee') {
              //   this.addErrorNotification('You must scan employee place!')
              //   return
              // }

              const query = {
                filter: [
                  { type: 'in', field: 'adapter', values: ['inventory', 'revision'] },
                  {
                    type: 'eq',
                    field: 'state',
                    value: 'active'
                  },
                  { type: 'eq', field: 'warehouse', value: place._embedded.warehouse.id }
                ]
              }

              return this.$service.picking.getAll(query)
                  .then(({ items }) => {
                    this.place = barcode.value
                    this.$channels.user.publish('openPlace', this.place, place)

                    this.queues = items.map(data => {
                      return {
                        type: 'Orderadmin\\Storage\\Entity\\Picking\\Queue',
                        event: 'storage.tasks.queue.opened',
                        data
                      }
                    })

                    this.action = 'next'
                    if (this.helper) {
                      this.removeHelper(this.helper.id)
                    }
                  })
            })
      }

      this.barcode = barcode
    }
  }
}
</script>
